// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import { useEffect, useRef } from "react"
import { useInView, useMotionValue, useSpring } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"
/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */

export default function Counter({
    decimal,
    value,
    decimalValue,
    direction,
    stiffness,
    damping,
    prefix,
    prefixText,
    suffix,
    suffixText,
    color,
    fontStyle,
}) {
    const initialValue = 0
    const ref = useRef<HTMLSpanElement>(null)
    const updatedDecimalValue = decimal === true ? decimalValue : value
    const motionValue = useMotionValue(
        direction === "down" ? updatedDecimalValue : initialValue
    )
    const springValue = useSpring(motionValue, {
        damping: damping,
        stiffness: stiffness,
    })
    const isInView = useInView(ref, { once: true, margin: "-100px" })
    useEffect(() => {
        if (isInView) {
            motionValue.set(
                direction === "down" ? initialValue : updatedDecimalValue
            )
        }
    }, [motionValue, isInView])

    useEffect(
        () =>
            springValue.on("change", (latest) => {
                if (ref.current) {
                    ref.current.textContent = Intl.NumberFormat("en-US").format(
                        latest.toFixed(decimal === true ? 2 : 0)
                    )
                }
            }),
        [springValue]
    )

    return (
        <>
            {prefix ? (
                <span style={{ ...fontStyle, color: color }}>{prefixText}</span>
            ) : null}
            <span ref={ref} style={{ ...fontStyle, color: color }}>
                {initialValue}
            </span>
            {suffix ? (
                <span style={{ ...fontStyle, color: color }}>{suffixText}</span>
            ) : null}
        </>
    )
}

Counter.defaultProps = {
    decimal: false,
    value: 100,
    decimalValue: 100,
    direction: "up",
    stiffness: 300,
    damping: 100,
    prefix: false,
    prefixText: "",
    suffix: false,
    suffixText: "",
    color: "#000000",
    fontStyle: "",
}
addPropertyControls(Counter, {
    decimal: {
        title: "Decimal",
        type: ControlType.Boolean,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    value: {
        title: "Value",
        type: ControlType.Number,
        min: 0,
        max: 1000,
        hidden(props) {
            return props.decimal === true
        },
    },
    decimalValue: {
        title: "Value",
        type: ControlType.Number,
        min: 0,
        max: 1000,
        step: 0.01,
        hidden(props) {
            return props.decimal === false
        },
    },
    prefix: {
        title: "Prefix",
        type: ControlType.Boolean,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    prefixText: {
        title: "Prefix Value",
        type: ControlType.String,
        hidden(props) {
            return props.prefix === false
        },
    },
    suffix: {
        title: "Suffix",
        type: ControlType.Boolean,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    suffixText: {
        title: "Suffix Value",
        type: ControlType.String,
        hidden(props) {
            return props.suffix === false
        },
    },
    direction: {
        title: "Direction",
        type: ControlType.Enum,
        options: ["up", "down"],
    },

    stiffness: {
        title: "Stiffness",
        type: ControlType.Number,
        min: 1,
        max: 1000,
    },
    damping: {
        title: "Damping",
        type: ControlType.Number,
        min: 0,
        max: 100,
    },
    color: {
        title: "Color",
        type: ControlType.Color,
    },
    fontStyle: {
        title: "Font Style",
        type: "font",
        controls: "extended",
    },
})
